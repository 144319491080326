import CryptoJS from 'crypto-js';


/**
 * 加密 --api
 * @param word string
 * @returns {*} string
 */
export function generatePassword(password) {
    if (password == null) {
        password = ''
    }
    const neededLength = 32 - password.length;
    if (neededLength <= 0) {
        return password;
    }
    const paddingArray = new Array(neededLength).fill('a').map(() => 'a');
    return password + paddingArray.join('');
}

export function encrypt(content, key) {//word 为 string  mode为加密规则
    key = generatePassword(key)
    var secretKey = CryptoJS.enc.Utf8.parse(key);//key  为你的秘钥
    var srcs = CryptoJS.enc.Utf8.parse(content);
    var encrypted = CryptoJS.AES.encrypt(srcs, secretKey, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
}

/**
 * 解密 --api
 * @param word string
 * @returns {*} string
 */
export function decrypt(content, key) {
    key = generatePassword(key)
    var secretKey = CryptoJS.enc.Utf8.parse(key);
    var decrypt = CryptoJS.AES.decrypt(content, secretKey, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}