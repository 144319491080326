<template>
    <div :style="styleTotal">
        <back></back>
        <div class="noteType" v-if="noteType!== null">
            <div class="note-type" style="background-color: #007BFF" @click="changeType(1)">写纸条</div>
            <div class="note-type" style="background-color: #42b983" @click="changeType(2)">取纸条</div>
        </div>
        <div class="noteType2" v-if="noteType == null">
            <div class="note-type2" style="background-color: #007BFF;" @click="noteType=1">写纸条</div>
            <div class="note-type2" style="background-color: #42b983;margin-top: 30px" @click="noteType=2">取纸条</div>
        </div>
        <div v-if="noteType === 1">
            <textarea class="content" :style="styleContent" v-model="content" placeholder="输入内容"
                      type="text"></textarea>
            <div style="display: flex">
                <textarea class="password1" :style="styleCode" v-model="password" placeholder="输入加密密码"
                          onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"></textarea>
                <div class="password2" :style="styleCode" @click="encrypt()">确定</div>
            </div>
            <div style="display: flex">
                <textarea class="code" :style="styleCode" v-model="code" placeholder="输入分享密码"></textarea>
                <div class="code2" :style="styleCode" @click="generateCode()">随机生成</div>
            </div>
            <div class="save" :style="styleSave" :data-clipboard-text="link" @click="save()">保存并分享</div>
        </div>
        <div v-if="noteType === 2">
            <div style="display: flex">
                <textarea class="code" :style="styleCode" v-model="code" placeholder="输入分享密码"></textarea>
                <div class="code2" :style="styleCode" @click="getNote()">确定</div>
            </div>
            <textarea class="type2Content" :style="style2Content" v-model="content"
                      placeholder="纸条加密内容"></textarea>
            <div style="display: flex">
                <textarea class="type2Password" :style="styleCode" v-model="password"
                          onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" placeholder="输入加密时的密码"></textarea>
                <div class="type2Password2" :style="styleCode" @click="decrypt()">确定</div>
            </div>
            <textarea class="type2Content" :style="style2Content" v-model="decryptContent"
                      placeholder="纸条解密内容"></textarea>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {decrypt, encrypt} from "@/utils/AESUtil";
import ClipboardJS from "clipboard";
import {addNote, checkRepeatCode, getAnswer, getNote} from "@/api/yunoApi";
import {getTime2} from "@/utils/parse";

export default {
    name: "note",
    components: {
        Back
    },
    data() {
        return {
            isEncrypt: false,
            noteType: null,
            code: null,
            link: 'https://www.yunox.cn/#/notes',
            password: '',
            content: null,
            decryptContent: null,
            screenHeight: 0,
            styleTotal: {
                height: '0px' // 初始化height为0px
            },
            styleContent: {
                height: '0px' // 初始化height为0px
            },
            style2Content: {
                height: '0px' // 初始化height为0px
            },
            stylePassword: {
                height: '0px' // 初始化height为0px
            },
            styleCode: {
                height: '0px' // 初始化height为0px
            },
            styleSave: {
                height: '0px' // 初始化height为0px
            }
        }
    },
    methods: {
        save() {
            if (this.content === null) {
                alert("已复制，内容为空")
                return;
            }
            if (this.code !== null && this.code.length > 0) {
                if (this.isEncrypt === false && this.password !== null && this.password.length > 0) {
                    this.encrypt()
                }
                if (this.code.length > 240) {
                    alert("口令太长了，不能超过240个字符")
                    return;
                }
                const note = {
                    content: this.content,
                    code: this.code
                }
                checkRepeatCode(note).then(res => {
                    if (res === true) {
                        addNote(note).then()
                    } else {
                        alert("纸条口令重复，换一个")
                    }
                })
                alert("已复制")
            }
            if (this.code === null || this.code.length === 0) {
                alert("已复制但是未上传")
            }
        },
        generateCode() {
            getAnswer().then(res => {
                if (getTime2() % 2 === 0) {
                    this.code = res.answerZH
                } else {
                    this.code = res.answerEn
                }
            })
        },
        getNote() {
            if (this.code !== null && this.code.length > 0) {
                getNote(this.code).then(res => {
                    this.content = res.content
                })
            }
        },
        changeType(value) {
            this.resetData()
            this.noteType = value
        },
        resetData() {
            this.code = null
            this.password = null
            this.content = null
            this.decryptContent = null
        },
        encrypt() {
            if (this.content === null || this.content.length < 1) {
                alert("没输内容")
                return;
            }
            if (this.password.length > 28) {
                alert("密码长度不得超过28个字符")
                return;
            }
            if (this.password.length < 1) {
                alert("请输入密码")
                return;
            }
            this.isEncrypt = true
            this.content = encrypt(this.content, this.password)
        },
        decrypt() {
            if (this.password !== null && this.password.length > 0) {
                this.decryptContent = decrypt(this.content, this.password)
            }
        },
        getScreenHeight() {
            this.screenHeight = window.innerHeight;
            this.styleTotal.height = `${this.screenHeight / 3}px`; // 更新styleObject的高度
            this.styleContent.height = `${this.screenHeight / 4}px`; // 更新styleObject的高度
            this.style2Content.height = `${this.screenHeight / 4.5}px`; // 更新styleObject的高度
            this.stylePassword.height = `${this.screenHeight / 10}px`; // 更新styleObject的高度
            this.styleCode.height = `${this.screenHeight / 10}px`; // 更新styleObject的高度
            this.styleSave.height = `${this.screenHeight / 8}px`; // 更新styleObject的高度
        },
        updateUrl() {
            // 构建基础 URL
            let baseUrl = 'https://www.yunox.cn/#/notes';
            let urlParams = new URLSearchParams(window.location.search);

            // 更新或设置参数
            if (this.code) {
                urlParams.set('code', this.code);
            } else {
                urlParams.delete('code');
            }

            if (this.password) {
                urlParams.set('pwd', this.password);
            } else {
                urlParams.delete('pwd');
            }

            // 构建新的 URL
            let newUrl = baseUrl + '?' + urlParams.toString();
            // 如果你希望在 URL 更新时不刷新页面，可以设置 this.link 而不是直接修改 location
            this.link = newUrl;
        }
    },
    mounted() {
        new ClipboardJS('.save')
        this.getScreenHeight()
    },
    created() {
        const hash = window.location.hash;
        // 检查哈希是否为期望的路由
        if (hash.includes('#/notes')) {
            // 获取查询参数的值
            const searchParams = new URLSearchParams(hash.split('?')[1]);
            const code = searchParams.get('code');
            const pwd = searchParams.get('pwd');
            // 只有当code和pwd都不为null时才进行后续操作
            if (code !== null || pwd !== null) {
                // 设置noteType的值
                this.noteType = 2;
                if (code) {
                    this.code = code;
                    this.getNote()
                    if (pwd) {
                        setTimeout(() => {
                            this.password = pwd;
                            this.decrypt()
                        }, 500);

                    }
                } else {
                    this.password = pwd
                }
            }
        } else {
            // 执行默认逻辑的代码
        }
    },
    watch: {
        // 监听 code 的变化
        code(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.updateUrl();
            }
        },
        // 监听 password 的变化
        password(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.updateUrl();
            }
        }
    },
}
</script>

<style scoped>
textarea::placeholder {
    color: #ffffff;
    font-size: 25px;
    font-weight: bolder;
    align-items: start; /* 水平居中 */
}

.noteType {
    margin-bottom: 10px;
    margin-top: 40px;
    padding: 10px;
    display: flex;
    gap: 5%;
}

.note-type {
    color: white;
    font-size: 22px;
    font-weight: bolder;
    display: flex; /* 启用Flexbox布局 */
    flex: 1; /* 让元素在父容器中占据剩余空间 */
    width: 40%; /* 设置宽度为40% */
    height: 100px; /* 设置高度为100px */
    border-radius: 30px; /* 设置圆角为30px */
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    /* 以下属性是可选的，用于确保flex容器的高度不会影响其内容的居中 */
    padding: 0; /* 移除内边距 */
    margin: 0; /* 移除外边距 */
    box-sizing: border-box; /* 边框计算在宽度和高度内 */
    box-shadow: 3px 4px 8px rgba(86, 77, 77, 0.7); /* 添加阴影效果 */
    cursor: pointer;
}

.noteType2 {
    margin-top: 60px;
    padding-left: 20%;
    padding-right: 20%;
}

.note-type2 {
    color: white;
    font-size: 22px;
    font-weight: bolder;
    display: flex; /* 启用Flexbox布局 */
    width: 100%; /* 设置宽度为40% */
    height: 300px; /* 设置高度为100px */
    border-radius: 30px; /* 设置圆角为30px */
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    box-shadow: 3px 4px 8px rgba(86, 77, 77, 0.7); /* 添加阴影效果 */
    cursor: pointer;
}

.note-type2:hover {
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
}

.note-type:hover {
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
}

.content {
    background-color: rgba(3, 140, 63, 0.34);
    width: 96%;
    border: solid 1px #d08080;
    margin-bottom: 30px;
    align-items: start; /* 水平居中 */
    font-size: 16px;

}


.password1 {
    flex: 4;
    display: flex; /* 启用Flexbox布局 */
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #f8a527;
    margin-right: 3%;
    font-size: 18px;
}

.password2 {
    flex: 1;
    display: flex; /* 启用Flexbox布局 */
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #f8a527;
    font-size: 17px;
    font-weight: bolder;
    cursor: pointer;
}

.password2:hover {
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
}

.code {
    flex: 4;
    display: flex; /* 启用Flexbox布局 */
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: coral;
    margin-right: 3%;
    font-size: 17px;

}

.code2 {
    flex: 1;
    display: flex; /* 启用Flexbox布局 */
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: coral;
    font-size: 17px;
    font-weight: bolder;
    cursor: pointer;

}

.code2:hover {
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
}

.save {
    cursor: pointer;
    font-size: 22px;
    color: white;
    font-weight: bolder;
    width: 60%;
    display: flex; /* 启用Flexbox布局 */
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    border-radius: 10px;
    background-color: #27b9f8;
    margin-left: 20%;
    margin-right: 20%;
    cursor: pointer;

}

.save:hover {
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
}

.type2Content {
    background-color: rgba(3, 140, 63, 0.34);

    width: 100%;
    border: solid 1px #d08080;
    margin-bottom: 30px;
    font-size: 17px;

}


.type2Password {
    flex: 4;
    display: flex; /* 启用Flexbox布局 */
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #f8a527;
    margin-right: 3%;
    font-size: 17px;
}

.type2Password2 {
    flex: 1;
    display: flex; /* 启用Flexbox布局 */
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #f8a527;
    font-size: 17px;
    font-weight: bolder;
    cursor: pointer;

}

.type2Password2:hover {
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
}
</style>