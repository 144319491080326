<template>
    <div>
        <div class="home_title">
            <img src="../assets/img/YunOx3.png" @click="openAdministrator()">
        </div>
        <navigationBar></navigationBar>
        <div class="home">
            <div v-for="row in page" :key="row" @click="goto(row.path)" class="menu"
                 :style="`background-image: url(${row.background})`"> {{ row.name }}
            </div>
        </div>
    </div>
</template>

<script>
import {goto} from "@/utils/pathUtil";
import NavigationBar from "@/components/navigationBar.vue";

export default {
    name: "home",
    components: {NavigationBar},
    methods: {
        openAdministrator() {
            this.clickTime += 1
            if (this.clickTime > 3) {
                this.goto('backOffice')
            }
        },
        setSeo() {
            // 设置关键词标签
            let keywordsContent = 'Lachesism我是风暴';
            let keywordsMeta = document.querySelector('meta[name="keywords"]');
            if (!keywordsMeta) {
                keywordsMeta = document.createElement('meta');
                keywordsMeta.setAttribute('name', 'keywords');
                document.head.appendChild(keywordsMeta);
            }
            keywordsMeta.setAttribute('content', keywordsContent);

            // 设置描述标签
            let descriptionContent = '我是风暴';
            let descriptionMeta = document.querySelector('meta[name="description"]');
            if (!descriptionMeta) {
                descriptionMeta = document.createElement('meta');
                descriptionMeta.setAttribute('name', 'description');
                document.head.appendChild(descriptionMeta);
            }
            descriptionMeta.setAttribute('content', descriptionContent);

            // 设置标题
            document.title = 'Lachesism';
        },
        goto
    },
    mounted() {
        this.setSeo()
    },
    data() {
        return {
            clickTime: 0,
            page: [
                {'name': '云顶阵容', 'path': 'lineup', 'background': require('@/assets/img/cover/10110.jpg')},
                {'name': '答案之书', 'path': 'answer', 'background': require('@/assets/img/cover/answerBook.jpeg')},
                {
                    'name': '星座运势️',
                    'path': 'constellation',
                    'background': require('@/assets/img/cover/universe.png')
                },
                {'name': '算命轮盘', 'path': 'gossip', 'background': require('@/assets/img/cover/gossip.png')},
                {'name': '记账本', 'path': 'bill', 'background': require('@/assets/img/cover/bill.png')},
                {'name': '文件转发', 'path': 'postal', 'background': require('@/assets/img/cover/mail.png')},
                {'name': '小纸条', 'path': 'notes', 'background': require('@/assets/img/cover/note.jpeg')},
                {'name': '人物大全', 'path': 'characters', 'background': require('@/assets/img/cover/ultraman.png')},
                {'name': '每天吃什么', 'path': 'eat', 'background': require('@/assets/img/cover/food.png')},
                {'name': '待办事项', 'path': 'todoList', 'background': require('@/assets/img/cover/todoList.png')},
                {'name': '快捷复制器', 'path': 'copy', 'background': require('@/assets/img/cover/copy.png')},
                {'name': '余额截图', 'path': 'wechat', 'background': require('@/assets/img/cover/noMoney.png')},
                {'name': '十万个不为什么', 'path': 'question', 'background': require('@/assets/img/cover/whyBg.png')},
                {'name': '刑法大全', 'path': 'law', 'background': require('@/assets/img/cover/law.png')},
                {'name': '牌', 'path': 'introductory', 'background': require('@/assets/img/cover/balckSun.png')},
                {'name': '每天100个单词', 'path': 'word', 'background': require('@/assets/img/cover/dictionary.png')},
                {'name': '老虎机', 'path': 'machine', 'background': require('@/assets/img/cover/jackpot.png')},
                {'name': '二维码生成器', 'path': 'QRCode', 'background': require('@/assets/img/cover/QRCode.png')}],
        }
    },

}
</script>

<style scoped>

.home {
    display: flex;
    margin-top: 3vh;
    flex-wrap: wrap; /* 允许子元素换行 */
    width: 100vw; /* 宽度设为视口宽度 */
    max-width: 100%; /* 确保最大宽度不超过视口宽度 */
    height: 98%;
    margin-left: -5px;
    padding: 1%; /* 给内容留出一些空间，避免紧贴边缘 */
    justify-content: space-around; /* 子元素在主轴上平均分布 */
}

.menu {
    color: rgb(251, 252, 251);
    text-shadow: 0 0 10px rgba(243, 4, 88, 0.7),
    0 0 20px rgba(243, 4, 88, 0.5),
    0 0 30px rgba(243, 4, 88, 0.3);
    font-weight: 900; /* 增加字重，使文字更粗 */
    font-size: 25px;
    font-family: "Heiti TC", sans-serif; /* 确保有合适的后备字体 */
    text-align: left;
    background-size: cover; /* 背景图片覆盖整个元素，保持宽高比 */
    flex: 1 1 20%;
    min-height: 30vh;
    min-width: 400px;
    border-radius: 8px;
    margin: 1vh;
    box-sizing: border-box;
    box-shadow: 3px 4px 8px rgba(86, 77, 77, 0.7); /* 添加阴影效果 */
}

.menu:hover {
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
}

.home_title {
    display: flex;
    padding-bottom: 1px;
}

.home_title img {
    height: 3vh; /* 图片高度设置为视口高度的3% */
    position: absolute; /* 设置图片为绝对定位 */
    left: 2vh; /* 将图片定位到容器的最左边 */
    top: 10px; /* 如果需要，可以设置图片的顶部位置，这里假设为顶部对齐 */
    cursor: pointer;
}
</style>