<template>
    <div :class="{'backGround1':season === 6,'backGround2':season === 11,'backGround3':season === 12}">
        <back></back>
        <div class="versionSelector">
            <div class="version" @click="changeSeason(6)" :class="{ 'bg-red': season !== 6 }">双城之战</div>
            <div class="version" @click="changeSeason(11)" :class="{ 'bg-red': season !== 11 }">画之灵</div>
            <div class="version" @click="changeSeason(12)" :class="{ 'bg-red': season !== 12 }">魔法大乱斗</div>
        </div>
        <div style="width: 200px;margin-left: 100px">

            <!--            <el-switch-->
            <!--                    style="display: block"-->
            <!--                    v-model="season"-->
            <!--                    active-color="#13ce66"-->
            <!--                    inactive-color="#13ce66"-->
            <!--                    active-text="双城之战"-->
            <!--                    active-value=6-->
            <!--                    inactive-text="画之灵"-->
            <!--                    inactive-value=11>-->
            <!--            </el-switch>-->
        </div>
        <!-- 遍历lineUpList中的每个对象 -->
        <div class="lineupTotal">
            <div v-for="item in lineUpList" :key="item.id" class="lineup" @click="getLineupDetail(item.id)">
                <!-- 输出对象的特定属性，例如id和formationName -->
                <div class="formation-name">{{ item.formationName }}</div>
                <!-- 容器用于垂直排列formationName和图片 -->
                <div class="image-container">
                    <!-- 如果item有formationInfoList属性，可以进一步遍历 -->
                    <div v-for="info in item.formationInfoList" :key="info.id" class="character-info">
                        <!-- 确保characterName是完整的URL -->
                        <img :src="info.characterName" alt="Character" class="character-image">
                        <div class="equipment">
                            <img v-if="info.equipmentOne" :src="info.equipmentOne" alt="Equipment One"
                                 class="equipment-image">
                            <img v-if="info.equipmentTwo" :src="info.equipmentTwo" alt="Equipment Two"
                                 class="equipment-image">
                            <img v-if="info.equipmentThree" :src="info.equipmentThree" alt="Equipment Three"
                                 class="equipment-image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="lineUpList.length >= totalCount && isSearchActive === false">到底了</div>
        <create></create>
        <div class="search-container">
            <i class="el-icon-close" v-show="isSearchActive" @click="toggleSearch(false)"></i>
            <input class="search-input" type="text" v-show="isSearchActive" placeholder="输入英雄名" v-model="hero"/>
            <i class="el-icon-search" @click="toggleSearch(true)"></i> <!-- 假设您有一个搜索图标的类 -->

        </div>
    </div >
</template>
<script>

import {getLineupByHero, getLineupList} from "@/api/tftApi";
import {goto2} from "@/utils/pathUtil";
import Create from "@/components/create.vue";
import Back from "@/components/back.vue";


export default {
    name: "LineUpList",
    components: {Back, Create},
    data() {
        return {
            pageNum: 1,
            season: localStorage.getItem('season') ? parseInt(localStorage.getItem('season')) : 11,
            lineUpList: [],
            totalCount: 1,
            isSearchActive: false,
            hero: null,
            isFetching: false // 新增变量控制加载状态

        }
    },
    methods: {
        changeSeason(season) {
            this.season = season
            localStorage.setItem('season', season)
        },
        handleScroll() {
            if (this.isFetching) return; // 如果正在加载，则不执行
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            if (scrollTop + clientHeight >= scrollHeight - 200) { // 距离底部100像素时触发
                this.pageNum++; // 增加页码
                this.getLineUpList(); // 请求下一页数据
            }
        },
        toggleSearch(value) {
            if (value === false) {
                this.lineUpList = JSON.parse(localStorage.getItem('lineupList'))
                this.hero = null
            }
            this.isSearchActive = value;
            if (this.isSearchActive === true && value === true) {
                this.searchLineup()
            }
        },
        searchLineup() {
            const hero = this.hero ?? '';
            if (hero) {
                getLineupByHero(this.season, this.hero).then(res => {
                    this.lineUpList = res;
                })
            }
        },
        getLineupDetail(id) {
            goto2(this.$router, 'tft', {id: id}); // 调整为你的路由名称和参数
        },
        getLineUpList() {
            if (this.isFetching || this.lineUpList.length >= this.totalCount) return; // 如果正在加载，则不执行
            this.isFetching = true; // 设置加载状态为true
            getLineupList(this.pageNum, this.season).then(res => {
                this.lineUpList = [...this.lineUpList, ...res.list];
                localStorage.setItem('lineupList', JSON.stringify(this.lineUpList))
                this.totalCount = res.totalCount
                this.isFetching = false; // 请求完成后设置加载状态为false
            }).catch(error => {
            });
        },
        setSeo() {
            // 设置关键词标签
            let keywordsContent = '金铲铲之战, 云顶之弈, 阵容分享, 游戏策略, 画之灵, 双城传说, 赛季最强';
            let keywordsMeta = document.querySelector('meta[name="keywords"]');
            if (!keywordsMeta) {
                keywordsMeta = document.createElement('meta');
                keywordsMeta.setAttribute('name', 'keywords');
                document.head.appendChild(keywordsMeta);
            }
            keywordsMeta.setAttribute('content', keywordsContent);

            // 设置描述标签
            let descriptionContent = '发现并掌握金铲铲之战/云顶之弈中最强大的阵容。我们的专家玩家团队精心挑选并分享多种阵容，帮助你在每个赛季中占据优势。加入我们的阵容分享社区，提升你的游戏水平。';
            let descriptionMeta = document.querySelector('meta[name="description"]');
            if (!descriptionMeta) {
                descriptionMeta = document.createElement('meta');
                descriptionMeta.setAttribute('name', 'description');
                document.head.appendChild(descriptionMeta);
            }
            descriptionMeta.setAttribute('content', descriptionContent);

            // 设置标题
            document.title = '顶尖阵容分享 - 金铲铲之战/云顶之弈最强策略';
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll); // 监听滚动事件
        this.getLineUpList()
        this.setSeo()

    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll); // 组件销毁时移除事件监听
    },
    watch: {
        season(newVal) {
            this.pageNum = 1
            this.lineUpList = []
            this.getLineUpList()
            localStorage.setItem('season', newVal)
        }
    }

}
</script>

<style scoped>
.lineupTotal {
    display: flex;
    margin-top: 60px;
    flex-wrap: wrap; /* 允许子元素换行 */
    width: 95vw; /* 宽度设为视口宽度 */
    max-width: 100%; /* 确保最大宽度不超过视口宽度 */
    height: 98%;
    padding: 1%; /* 给内容留出一些空间，避免紧贴边缘 */
    justify-content: space-around; /* 子元素在主轴上平均分布 */
}

.lineup {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* 让内容从左侧开始 */
    border: 1px solid #ddd;
    padding: 8px;
    margin: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 98vw;
}

.formation-name {
    text-align: left; /* 文本左对齐 */
    font-weight: bold;
    width: 100%; /* 让标题占满宽度 */
}

.image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    max-width: 100%; /* 确保图片容器宽度 */
}

.character-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2px;
}

.character-image {
    width: 4vh;
    height: auto;
}

.equipment {
    display: flex;
    justify-content: center;
}

.equipment img {
    width: 10px;
    height: 10px;
    margin: 0 0.5px;
}

.search-container {
    border: 1px solid #0000ff; /* 边框颜色 */
    background-color: rgba(98, 208, 255); /* 按钮背景颜色 */
    border-radius: 10px;
    color: white; /* 文字颜色 */
    width: 35px; /* 按钮宽度 */
    height: 35px; /* 按钮高度，圆形时宽高应一致 */
    padding: 0; /* 由于是圆形按钮，移除padding，文字居中可能需要其他方法 */
    position: fixed; /* 使用 fixed 定位 */
    top: 5px; /* 距离底部的距离，增加以提供更多空间 */
    right: 10px; /* 距离右侧的距离，增加以提供更多空间 */
    z-index: 99; /* 确保按钮在其他内容上方 */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; /* 鼠标悬浮时显示手型图标 */
    user-select: none; /* 防止用户选择文本 */
    font-size: 24px; /* 增大文字大小 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 添加阴影，提升按钮层次感 */
}

.el-icon-search {
    z-index: 999; /* 确保按钮在其他内容上方 */
    padding-right: 10px;
    padding-left: 10px;
}

.el-icon-close {
    top: 10%; /* 距离底部的距离，增加以提供更多空间 */
    right: 130px;
    position: absolute;
    color: #ff4d4d;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.search-input {
    border-radius: 11px;
    margin-left: 1px;
    width: 70px; /* 输入框宽度 */
    height: 40px;
    border: 1px solid #0000ff; /* 边框颜色 */
    outline: none; /* 点击时不显示轮廓 */
    transition: width 0.3s ease; /* 宽度变化的过渡效果 */
}

.versionSelector {
    display: flex;
    width: 95vw;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    position: absolute;
    margin-top: 30px;
    gap: 10px;
}

.version {
    cursor: pointer;
    font-weight: bolder;
    border-radius: 10px;
    height: 30px;
    box-shadow: 1px 2px 2px rgba(86, 77, 77, 0.7); /* 添加阴影效果 */
    border: #2a738d 1px solid;
    width: 30vw; /* 每个元素占据视口宽度的30% */
    flex-grow: 1; /* 使元素等宽 */
    flex-basis: 0; /* 基础宽度为0，由flex-grow分配空间 */
    background-color: #ff8585;
    color: white;
}

.bg-red {
    background-color: rgba(255, 255, 255, 0.2);
}
.backGround1{
    background-image: url("https://www.yunox.cn/image/s6_bg.jpg");
    background-size: cover; /* 确保图片覆盖整个元素区域 */

}
.backGround2{
    background-image: url("https://www.yunox.cn/image/s11_bg.png");
    background-size: cover; /* 确保图片覆盖整个元素区域 */

}
.backGround3{
    background-image: url("https://www.yunox.cn/image/s12_bg.jpg");
    background-size: cover; /* 确保图片覆盖整个元素区域 */

}
</style>